<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="短剧名称" prop="videoId" >
        <!--        <a-input v-model="form.videoId" placeholder="请输入短剧id" />-->
        <a-select
          v-model="form.videoId"
          showSearch
          style="width: 100%"
          placeholder="请选择短剧"
          allow-clear
          disabled
          optionFilterProp="children"
        >
          <a-select-option :value="item.id" :key="item.id" :title="item.videoName" v-for="(item,index) in listAll">
            {{item.videoName}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="当前集数" prop="serialNumber" >
        <a-input-number :min="1" v-model="form.serialNumber" placeholder="请输入当前集数" />
      </a-form-model-item>
<!--      <a-form-model-item label="购买类型" prop="type" >-->
<!--&lt;!&ndash;        <a-input v-model="form.type" placeholder="请输入是否可以使用金币购买 0-否 1-是" />&ndash;&gt;-->

<!--        <a-select placeholder="请选择购买类型" v-model="form.type">-->
<!--          <a-select-option :key="0">免费</a-select-option>-->
<!--          <a-select-option :key="1">金币</a-select-option>-->
<!--          <a-select-option :key="2">钻石</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item label="短剧时长" prop="duration" >-->
<!--        <a-input v-model="form.duration" placeholder="请输入短剧时长" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="视频" prop="videoUrl" >
<!--        <a-input v-model="form.videoUrl" placeholder="请输入短剧url" />-->
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          v-model="form.videoUrl"
          action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"
          :showUploadList="false"
          :beforeUpload="beforeUploadVideo"
          :customRequest="handleChange"
        >
          <video :src="form.videoUrl" v-if="form.videoUrl" width="300" height="150" controls></video>

<!--          <img v-if="form.videoUrl" :src="form.videoUrl" alt="logo"-->
<!--               style="height:104px;max-width:300px"/>-->
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>

            <div class="ant-upload-text">上传</div>
          </div>
<!--          <a-spin v-if="uploadLoading" />-->
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="中文字幕" prop="subtitleCn">
<!--           action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"-->

<!--        <iframe v-if="this.form.subtitleCn" :src="this.form.subtitleCn" width="600" height="300"></iframe>-->
        <a-upload
          action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"
          v-model="this.form.subtitleCn"
          :show-upload-list="false"
          :customRequest="handleChangeCn"
        >

          <a-button type="primary" v-if="this.form.subtitleCn">更换</a-button>
          <a-button v-else> <a-icon type="upload" /> 上传{{uploadLoadings ? '中':''}} </a-button>
        </a-upload>

        <div style="margin-top: 10px;margin-bottom: 10px">


        </div>
      </a-form-model-item>
<!--      action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"-->
      <a-form-model-item label="英文字幕" prop="subtitleEn">
<!--        {{fileListEn}}-->
        <a-upload
          action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"
          :showUploadList="false"
          :customRequest="handleChangeEn"
        >
          <a-button type="primary" v-if="form.subtitleEn">更换</a-button>
          <a-button v-else> <a-icon type="upload" /> 上传{{uploadLoadings ? '中':''}} </a-button>
        </a-upload>
      </a-form-model-item>
      <!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getGather, addGather, updateGather } from '@/api/video/gather'
import { listVideo } from '@/api/video/video'
import TagSelectOption from "@/components/TagSelect/TagSelectOption";
import UploadFileToVOD from "@/api/vod.js"
import axios from "axios";
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    TagSelectOption
  },
  data () {
    return {
      listAll:[],
      fileZhList:[],
      submitLoading: false,
      formTitle: '',
      progress: 0,
      uploadLoading:false,
      uploadLoadings: false,
      // 表单参数
      form: {
        id: null,

        type: null,

        duration: null,

        videoUrl: null,

        videoId: null,

        serialNumber: null,

        createTime: null,

        remark: null,
        subtitleCn: null,
        subtitleEn: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      fileList:[],
      fileListEn:[],
      rules: {
        type: [
          { required: true, message: '购买类型不能为空' }
        ],
        duration: [
          { required: true, message: '短剧时长不能为空' }
        ],
        videoUrl: [
          { required: true, message: '视频不能为空' }
        ],
        videoId: [
          { required: true, message: '短剧名称不能为空' }
        ],
        serialNumber: [
          { required: true, message: '当前集数不能为空' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getGather();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    computedUploadZhing() {
      if(this.fileZhList.length >0) {
        return this.fileZhList.filter(i => {
          return i.percent != 100 || i.url === ''
        }).length;
      } else {
        return 0;
      }
    },
    beforeUploadVideo:function (file) {
      debugger
      var fileType = file.type;
      if (fileType.indexOf('video') < 0) {
        this.$message.warning('请上传视频');
        this.form.videoUrl = '';
        return false
      }

      //TODO 驗證文件大小
    },
    handleChangeCn(info) {

      let that = this;
      let FormDatas = new FormData();
      FormDatas.append('file', info.file)
      axios({
        method: 'post',
        url: "https://web.frameflash.com/admin-api/common/local/file/uploadCos", // 接口地址
        data: FormDatas,
        // 请求配置按需配置即可
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data;boundary=' + new Date().getTime()
        },
      }).then((res) => {

          that.form.subtitleCn = res.data.data.mediaUrl;//文件名
              that.$message.success("操作成功")
      })




    },
    handleChangeEn(info) {
      let that = this;
      let FormDatas = new FormData();
      FormDatas.append('file', info.file)
      axios({
        method: 'post',
        url: "https://web.frameflash.com/admin-api/common/local/file/uploadCos", // 接口地址
        data: FormDatas,
        // 请求配置按需配置即可
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data;boundary=' + new Date().getTime()
        },
      }).then((res) => {

        that.form.subtitleEn = res.data.data.mediaUrl;//文件名
        that.$message.success("操作成功")
      })
      // if (info.file.status === 'done') {
      //   console.log(info.file.response.data.mediaUrl);
      //   this.form.subtitleEn = info.file.response.data.mediaUrl;
      //   // Get this url from response in real world.
      //   // getBase64(info.file.originFileObj, imageUrl => {
      //   //   this.imageUrl = imageUrl;
      //   //   this.uploadLoading = false;
      //   // });
      // } else {
      //   this.form.subtitleEn = "";
      // }
      return;
      if(info.file.status === 'removed') {
        this.fileListEn = []
      } else {
        let fileList = [...info.fileList];
        fileList[0].name = '文件'
        fileList = fileList.slice(-1);
        fileList = fileList.map(file => {
          if (file.response) {
            file.url = file.response.url;
          }
          return file;
        });
        this.fileListEn = fileList;
        if (info.file.status === 'done') {
          console.log(info.file.response.data.mediaUrl);
          this.form.subtitleEn = info.file.response.data.mediaUrl;
          // Get this url from response in real world.
          // getBase64(info.file.originFileObj, imageUrl => {
          //   this.imageUrl = imageUrl;
          //   this.uploadLoading = false;
          // });
        } else {
          this.form.subtitleEn = "";
        }
      }


    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true;
        return;
      }
      let that = this
      that.form.videoUrl = ""
      new UploadFileToVOD([info.file], {
        success: function (fileName) {

          that.form.videoUrl = fileName[0];//文件名
          that.uploadLoading = false;
        },
        vProgress:function (val) {
          console.log('++++++++++')
          console.log(val)
        }
      });
      that.uploadLoading = false;



    },
    handleUpload(info) { //上传主图(一张图)
      console.log(info)
      let that = this;
      this.form.videoUrl = "";
      this.uploadLoading = true;
      // ossUpload(info.file)
      //  , function (fileName) {
      //   console.log('--------------------------------')
      //   console.log(fileName)
      //   console.log('--------------------------------')
      //   that.uploadLoading = false;
      //   that.form.coverUrl = fileName;
      // }
    },
    getGather() {
      listVideo().then(res => {
        this.listAll = res.data;
      })
    },
    onClose () {
      this.open = false
      this.uploadLoading = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        isGoldBuy: null,
        duration: null,
        videoUrl: null,
        videoId: null,
        serialNumber: null,
        createTime: null,
        remark: null,
        subtitleCn: null,
        subtitleEn: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGather({"id":id}).then(response => {
        this.form = response.data;
        console.log(1111)
        console.log(this.form)
        if(response.data.subtitleCn) {
          this.fileList=[{
            uid: '-1',
            name: '文件',
            status: 'done',
            url: response.data.subtitleCn,
          }]
        } else {
          this.fileList=[]
        }

        if(response.data.subtitleEn) {
          this.fileListEn=[{
            uid: '1',
            name: '文件',
            status: 'done',
            url: response.data.subtitleEn,
          }]
        } else {
          this.fileListEn=[]
        }

        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGather(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGather(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
